import { Button } from "components/ui";
import style from "./Account.module.css";
import cn from "classnames";

import Land from "assets/images/png/account-right.png";
import { SvgCalendear, SvgDownload } from "assets/images/svg";

const Account = () => {
  const figures = [
    {
      label: "Total mining",
      value: "181.351786 ALEO",
      color: "PURPLE",
    },
    {
      label: "Convertible ALEO",
      value: "181.351786 ALEO",
    },
    {
      label: "Withdrawable USDT",
      value: "181.351786 USDT",
      color: "PURPLE",
    },
    {
      label: "Hashrate",
      value: "198 TH/s",
    },
  ];
  return (
    <section className={cn(style.root)}>
      <div className={cn(style.land)}>
        <div className={cn(style.actions)}>
          <div className={cn(style.tabs)}>
            <span>Transfer</span>
            <span className={cn(style.active)}>Withdraw</span>
          </div>
          <div className={cn(style.box)}>
            <span className={cn(style.rate)}>1 ALEO = 35.12 USDT</span>
            <div className="flex items-center mt-16 mb-12 justify-between">
              <div>
                <p className="text-[#C3C8DE] text-4xl">0.0</p>
                <span className="text-[#602BF6]">Total Balance</span>
              </div>

              <div className="text-black">Aleo</div>
            </div>
            <Button
              sx="h-11 over-rounded-full font-bold"
              variant="purple-filled"
            >
              Transfer
            </Button>
          </div>
        </div>
        <div className={cn(style.landImg)}>
          <img src={Land} alt="land" />
        </div>
      </div>

      <div className={cn(style.earnings)}>
        <div className={cn(style.topbar)}>
          <p>My Earnings</p>
          <div className={cn(style.tools)}>
            <div className={cn(style.daterange)}>
              <span>2023-09-26 - 2023-10-26</span>
              <SvgCalendear />
            </div>
            <div className={cn(style.download)}>
              <SvgDownload />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap px-6 gap-y-8 mt-8  justify-between">
          {figures.map((figure, index) => (
            <div key={index} className="flex w-1/3 mr-1 justify-between">
              <span className="text-[#777777]">{figure.label}</span>
              <span
                className={
                  "font-bold " + (figure.color ? "text-[#602BF6]" : "")
                }
              >
                {figure.value}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className={cn(style.history)}>
        <div className={cn(style.topbar)}>
          <p>History</p>
        </div>
      </div>
    </section>
  );
};

export default Account;
