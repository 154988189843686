import create from "zustand";
import produce from "immer";

interface ISettingState {
  refreshedAt: Date;
  setRefreshedAt: {
    (_refreshedAt: Date): void;
  };
}

export const useSettingStore = create<ISettingState>((set, get) => ({
  refreshedAt: new Date(),
  setRefreshedAt: (_refreshedAt) => {
    set(
      produce((state: ISettingState) => {
        state.refreshedAt = _refreshedAt;
      })
    );
  },
}));
