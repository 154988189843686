import axios from "utils/axios";
import { I_USER } from "utils/interfaces/user.interface";

export const signin = async (email: string, password: string) => {
  return (
    await axios.post("auth/login", {
      email,
      password,
    })
  ).data;
};

export const signUp = async (email: string, password: string) => {
  return (
    await axios.post("auth/register", {
      email,
      password,
    })
  ).data;
};
export const sendVerification = async (email: string) => {
  return (
    await axios.post("auth/send-verification", {
      email,
    })
  ).data;
};

export const checkVerification = async (code: string): Promise<I_USER> => {
  return (await axios.post(`auth/verify-code`, { code })).data;
};

export const getMe = async () => {
  return (await axios.get("auth/profile")).data;
};
