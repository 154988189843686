import style from "./Header.module.css";
import cn from "classnames";

import { useCallback } from "react";
import { NavLink } from "react-router-dom";
// import { WalletMultiButton } from "@demox-labs/aleo-wallet-adapter-reactui";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";
import { WalletNotConnectedError } from "@demox-labs/aleo-wallet-adapter-base";
import { LeoWalletAdapter } from "@demox-labs/aleo-wallet-adapter-leo";

import LinkWithSearchParams from "components/LinkWithSearchParams";
import { Button } from "components/ui";
import { SvgArrowRight, SvgSpeaker } from "assets/images/svg";
import { useAccountStore } from "store";
import { WalletMultiButton } from "@demox-labs/aleo-wallet-adapter-reactui";

const Header = () => {
  const { wallet, publicKey } = useWallet();
  const { loggedIn } = useAccountStore();
  const onClick = useCallback(async () => {
    try {
      if (!publicKey) throw new WalletNotConnectedError();

      const message = "a message to sign";

      const bytes = new TextEncoder().encode(message);
      const signatureBytes = await (
        wallet?.adapter as LeoWalletAdapter
      ).signMessage(bytes);
      const signature = new TextDecoder().decode(signatureBytes);

      console.log(publicKey, signature);
    } catch (error) {
      console.error(error);
    }
  }, [wallet, publicKey]);

  const pageLinks = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "/about",
    },
    {
      text: "Products",
      link: "/products",
    },
    {
      text: "Tutorial",
      link: "/tutorial",
    },
    {
      text: "Statistics",
      link: "/statistics",
    },
  ];
  return (
    <div className={cn(style.root)}>
      <div className={cn(style.navbar)}>
        <LinkWithSearchParams
          to={{
            pathname: "/",
          }}
          className={cn(style.logo)}
        ></LinkWithSearchParams>
        <div className="hidden md:flex items-center mx-auto">
          {pageLinks.map((item, index) => (
            <NavLink
              key={index}
              to={{
                pathname: item.link,
              }}
              className={({ isActive }) =>
                `flex-1 px-4 w-24 ${isActive ? "font-bold" : ""}`
              }
            >
              {item.text}
            </NavLink>
          ))}
        </div>
        <div className="gap-4 flex justify-end">
          <div className="hidden wallet-connect-button">
            <WalletMultiButton />
          </div>
          {!loggedIn && (
            <>
              <NavLink to="/signup">
                <Button sx="bg-[#FFFFFF42] border-[transparent!important] rounded-[999px!important] w-24 h-9">
                  Sign up
                </Button>
              </NavLink>
              <NavLink to="/login">
                <Button sx="bg-[#FFFFFF42] border-[transparent!important] rounded-[999px!important] w-24 h-9">
                  Login
                </Button>
              </NavLink>
            </>
          )}
          {loggedIn && (
            <NavLink to="/account">
              <Button sx="bg-[#FFFFFF42] border-[transparent!important] rounded-[999px!important] w-32 h-9">
                My Account
              </Button>
            </NavLink>
          )}

          <Button className="hidden" onClick={onClick}>
            Sign
          </Button>
        </div>
      </div>
      <div className={cn(style.announcements)}>
        <SvgSpeaker className="mr-2" />
        <span> Announcements Announcements Announcements Announcements </span>

        <NavLink className="ml-8 flex items-center gap-2" to="/more">
          More <SvgArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
