import { Button, Input } from "components/ui";
import style from "./Singup.module.css";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useRef, useState } from "react";
import { SvgLoadingDots } from "assets/images/svg";
import { useAccountStore } from "store";
import { sendVerification } from "utils/apis/auth.api";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";

const Singup = () => {
  const { publicKey } = useWallet();
  const [loading, setLoading] = useState(false);
  const {
    register,
    checkVerification,
    registerProgress,
    setRegisterProgress,
    updateWalletAddress,
  } = useAccountStore();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);

  const walletRef = useRef<HTMLInputElement>(null);

  const onSendCode = async () => {
    sendVerification(emailRef.current!.value);
    alert("Verification code sent!");
  };

  // setRegisterProgress(2);

  const onSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      if (
        confirmPasswordRef.current!.value !== passwordRef.current!.value ||
        passwordRef.current!.value === ""
      ) {
        console.log(
          confirmPasswordRef.current!.value !== passwordRef.current!.value,
          passwordRef.current!.value === ""
        );
        alert("Confirm password not matching!");
        return;
      }

      if (loading) return;
      setLoading(true);

      const success = await register(
        emailRef.current!.value,
        passwordRef.current!.value
      );
      if (success) {
        setRegisterProgress(1);
        sendVerification(emailRef.current!.value);
        alert("Verification code sent!");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onTypeCode = async () => {
    if (codeRef.current!.value.length === 6) {
      setLoading(true);
      await checkVerification(codeRef.current!.value);
      setLoading(false);
      setRegisterProgress(2);
    }
  };

  return (
    <div className={cn(style.root)}>
      <form className={cn(style.panel)} onSubmit={onSignup}>
        <h3>Sign Up</h3>
        <div className={cn(style.phases)}>
          <div
            className={cn(
              style.phase,
              registerProgress === 1 ? style.active : ""
            )}
          >
            1
          </div>
          <span />
          <div
            className={cn(
              style.phase,
              registerProgress === 2 ? style.active : ""
            )}
          >
            2
          </div>
          <span />
          <div
            className={cn(
              style.phase,
              registerProgress === 3 ? style.active : ""
            )}
          >
            3
          </div>
        </div>
        {registerProgress === 1 && (
          <>
            <div className={cn(style.form)}>
              <label>Email</label>
              <Input
                type="email"
                sx="over-rounded-full w-80"
                placeholder="Please enter the email address"
                innerRef={emailRef}
              />
            </div>
            <div className={cn(style.form)}>
              <div className="flex justify-between">
                <label>Password</label>
              </div>
              <Input
                type="password"
                sx="over-rounded-full w-80"
                placeholder="8-20 characters, letters and numbers"
                innerRef={passwordRef}
              />
            </div>
            <div className={cn(style.form)}>
              <div className="flex justify-between">
                <label>Confirm Password</label>
              </div>
              <Input
                type="password"
                sx="over-rounded-full w-80"
                placeholder="Confirm new password"
                innerRef={confirmPasswordRef}
              />
            </div>
            <div className={cn(style.form)}>
              <div className="flex justify-between">
                <label>Verification code</label>
              </div>
              <div className="flex justify-between">
                <Input
                  sx="over-rounded-full w-[unset!important]"
                  placeholder="Please enter code"
                  innerRef={codeRef}
                  onChange={onTypeCode}
                />
                <Button
                  disabled={loading}
                  type="button"
                  sx="h-11 over-rounded-full font-bold"
                  variant="purple-filled"
                  onClick={onSendCode}
                >
                  Send Code
                </Button>
              </div>
            </div>

            <Button
              // disabled={loading}
              type="submit"
              sx="h-11 over-rounded-full font-bold"
              variant="purple-filled"
            >
              {loading ? <SvgLoadingDots /> : "Sign up"}
            </Button>
          </>
        )}
        {registerProgress === 2 && (
          <>
            <div className={cn(style.form)}>
              <label>Connect Wallet</label>
              <Input
                type="text"
                sx="over-rounded-full w-80"
                placeholder="Please enter the Aleo Wallet address"
                defaultValue={publicKey?.toString()}
                innerRef={walletRef}
              />

              <Button
                disabled={!!publicKey}
                type="button"
                sx="h-11 over-rounded-full font-bold"
                variant="purple-filled"
                onClick={() => {
                  (
                    document.querySelector(
                      ".wallet-connect-button button"
                    ) as HTMLButtonElement
                  ).click();
                }}
              >
                Connect Wallet
              </Button>
              <Button
                type="button"
                sx="h-11 over-rounded-full font-bold"
                variant="purple-filled"
                onClick={() => {
                  updateWalletAddress(walletRef.current!.value);
                  setRegisterProgress(3);
                }}
              >
                Next
              </Button>
            </div>
          </>
        )}

        {registerProgress === 3 && (
          <>
            <div className={cn(style.form)}>
              <label className="mx-auto">Completed!</label>
              <NavLink to="/" className="w-full">
                <Button
                  type="button"
                  sx="h-11 over-rounded-full font-bold w-full"
                  variant="purple-filled"
                >
                  DONE 👏
                </Button>
              </NavLink>
            </div>
          </>
        )}

        <div className="text-xs mt-3">
          Already have an account? <NavLink to="/login">Login</NavLink>
        </div>
      </form>
    </div>
  );
};

export default Singup;
