import { useEffect, useMemo } from "react";
import Footer from "components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "pages/Home";
import Header from "components/Header";
// import Borrow from "pages/BorrowDrawer";
import Navbar from "components/Navbar";
import { WalletProvider } from "@demox-labs/aleo-wallet-adapter-react";
import { WalletModalProvider } from "@demox-labs/aleo-wallet-adapter-reactui";
import { LeoWalletAdapter } from "@demox-labs/aleo-wallet-adapter-leo";
import {
  DecryptPermission,
  WalletAdapterNetwork,
} from "@demox-labs/aleo-wallet-adapter-base";
import Login from "pages/Login";
import { useAccountStore } from "store";
import RouteHiddenForAuthenticated from "components/Auth/RouteHiddenForAuthenticated";
import Singup from "pages/Singup";
import Account from "pages/Account";

require("@demox-labs/aleo-wallet-adapter-reactui/styles.css");

function App() {
  const { fetchAccount } = useAccountStore();
  const wallets = useMemo(
    () => [
      new LeoWalletAdapter({
        appName: "Leo Demo App",
      }),
    ],
    []
  );
  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);
  return (
    <WalletProvider
      wallets={wallets}
      decryptPermission={DecryptPermission.UponRequest}
      network={WalletAdapterNetwork.Testnet}
      autoConnect
    >
      <WalletModalProvider>
        <div className="main-container">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<div>About</div>} />

              <Route
                path="/signup"
                element={
                  <RouteHiddenForAuthenticated>
                    <Singup />
                  </RouteHiddenForAuthenticated>
                }
              />
              <Route
                path="/login"
                element={
                  <RouteHiddenForAuthenticated>
                    <Login />
                  </RouteHiddenForAuthenticated>
                }
              />
              <Route path="/account" element={<Account />} />
              <Route path="*" element={<div>404</div>} />
            </Routes>
          </main>
          <Footer />
          <Navbar />
        </div>
      </WalletModalProvider>
    </WalletProvider>
  );
}
export default App;
