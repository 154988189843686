import { Button, Input } from "components/ui";
import style from "./Home.module.css";
import cn from "classnames";
import {
  SvgWorld,
  SvgPercent,
  SvgClock,
  SvgLightning,
} from "assets/images/svg";
import "keen-slider/keen-slider.min.css";
import LinkWithSearchParams from "components/LinkWithSearchParams";

import Land from "assets/images/png/land.png";

const Home = () => {
  const figures = [
    {
      label: "Liquidity Sources",
      value: "345",
    },
    {
      label: "Total Volume",
      value: "$281B+",
    },
    {
      label: "Total Wallets",
      value: "4.4M+",
    },
    {
      label: "Total Trades",
      value: "28.7M+",
    },
  ];

  const states = [
    {
      icon: <SvgWorld />,
      label: "Deposit & Borrow TVL",
      value: "12,956.85 ETH",
      comment: "($21,155,944.68)",
    },
    {
      icon: <SvgPercent />,
      label: "ETH Deposit APR",
      value: "8.25%",
    },
    {
      icon: <SvgClock />,
      label: "Loans Value All Time",
      value: "25,056.29 ETH",
      comment: "($40,911,910.31)",
    },
    {
      icon: <SvgLightning />,
      label: "Open Loans",
      value: "1,250",
    },
  ];

  return (
    <section className={cn(style.root)}>
      <div className={cn(style.land)}>
        <div className={cn(style.description)}>
          <h1>
            The World’s
            <div className={cn(style.message)}>
              <div>Fastest</div>
              <div>Best</div>
              <div>Safest</div>
            </div>
          </h1>
          <h1>Aleo Mining pool</h1>
          <div className="text-xl">Start Aleo mining with Aleo Mine</div>
          <div className={cn(style.buttonBar)}>
            <LinkWithSearchParams to={{ pathname: "/borrow" }}>
              <Button sx="w-40 h-12 font-medium">Learn More</Button>
            </LinkWithSearchParams>
            <LinkWithSearchParams to={{ pathname: "/borrow" }}>
              <Button sx="w-40 h-12 font-medium" variant="white-filled">
                Start Mining
              </Button>
            </LinkWithSearchParams>
          </div>
        </div>
        <div className={cn(style.landImg)}>
          <img src={Land} alt="land" />
        </div>
      </div>

      <div className={cn(style.statistics)}>
        <p>Mining Statistics</p>
        <div className={cn(style.searchBar)}>
          <Input placeholder="Enter your wallet address" />
          <Button sx="w-20 text-[#00000080!important] border-[#E5E5E5!important]">
            Search
          </Button>
        </div>
        <span>Track your mining progress with real-time data.</span>
        <Button sx="w-40 h-12 font-medium" variant="black-filled">
          View More
        </Button>
      </div>
      <div className={cn(style.figures)}>
        {figures.map((figure, index) => (
          <div key={index}>
            <span>{figure.value}</span>
            <label>{figure.label}</label>
          </div>
        ))}
      </div>

      <div className={cn(style.states)}>
        {states.map((state, index) => (
          <div key={index} className={cn(style.state)}>
            {state.icon}

            <div className={cn(style.value)}>
              <span className={cn(style.label)}>{state.label}</span>

              <div>
                {state.value}
                <span>{state.comment}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Home;
