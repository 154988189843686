import { checkVerification, getMe, signUp, signin } from "utils/apis/auth.api";
import create from "zustand";
import produce from "immer";

interface IAccountState {
  email: string;
  loggedIn: boolean;
  registerProgress: number;
  /**
   * 1: verification code sent
   * 2: after email verification, need to verify wallet
   * 3: profile complete
   */

  setRegisterProgress: {
    (_registerProgress: number): any;
  };
  updateWalletAddress: {
    (_wallet: string): any;
  };
  login: {
    (email: string, password: string): Promise<boolean>;
  };
  register: {
    (email: string, password: string): Promise<boolean>;
  };
  checkVerification: {
    (code: string): Promise<boolean>;
  };
  fetchAccount: { (): Promise<any> };
}

export const useAccountStore = create<IAccountState>((set, get) => ({
  email: "",
  loggedIn: false,
  registerProgress: 0,

  setRegisterProgress: (progress: number) => {
    set(
      produce((state: IAccountState) => {
        state.registerProgress = progress;
      })
    );
  },
  updateWalletAddress: (wallet: string) => {},
  login: async (email: string, password: string) => {
    try {
      const result = await signin(email, password);
      localStorage.setItem("jwt_auth", result.access_token);
      set(
        produce((state: IAccountState) => {
          state.email = email;
          state.loggedIn = true;
        })
      );
      return true;
    } catch (error) {
      localStorage.removeItem("jwt_auth");
    }
    return false;
  },

  register: async (email: string, password: string) => {
    localStorage.removeItem("jwt_auth");
    try {
      const result = await signUp(email, password);
      localStorage.setItem("jwt_auth", result.access_token);

      set(
        produce((state: IAccountState) => {
          state.email = email;
          state.loggedIn = false;
        })
      );
      return true;
    } catch (error) {
      localStorage.removeItem("jwt_auth");
    }
    return false;
  },

  checkVerification: async (code: string) => {
    try {
      const user = await checkVerification(code);
      if (user?.emailVerified)
        set(
          produce((state: IAccountState) => {
            state.email = user.email;
            state.loggedIn = true;
            state.registerProgress = 2;
          })
        );
      return true;
    } catch (error) {
      // localStorage.removeItem("jwt_auth");
    }
    return false;
  },

  fetchAccount: async () => {
    try {
      const profile = await getMe();
      set(
        produce((state: IAccountState) => {
          state.email = profile.email;
          state.loggedIn = true;
        })
      );
      return;
    } catch (error) {
      set(
        produce((state: IAccountState) => {
          state.email = "";
          state.loggedIn = false;
        })
      );
    }
  },
}));
