import AXIOS from "axios";

const axios = AXIOS.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(function (config) {
  const token = `Bearer ${localStorage.getItem("jwt_auth")}`;
  config.headers.Authorization = token;
  return config;
});

export default axios;
