import { Button, Input } from "components/ui";
import style from "./Login.module.css";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useRef, useState } from "react";
import { SvgLoadingDots } from "assets/images/svg";
import { useAccountStore } from "store";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useAccountStore();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const onLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);

      const success = await login(
        emailRef.current!.value,
        passwordRef.current!.value
      );
      if (success) alert("Logged in Successfully!");
      else {
        alert("Invalid password");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className={cn(style.root)}>
      <form className={cn(style.panel)} onSubmit={onLogin}>
        <h3>Login</h3>

        <div className={cn(style.form)}>
          <label>Email</label>
          <Input
            type="email"
            sx="over-rounded-full w-80"
            placeholder="Please enter the email address"
            innerRef={emailRef}
          />
        </div>
        <div className={cn(style.form)}>
          <div className="flex justify-between">
            <label>Password</label>
            <span className={cn(style.warning)}>Forgot password?</span>
          </div>
          <Input
            type="password"
            sx="over-rounded-full w-80"
            placeholder="Please enter the email address"
            innerRef={passwordRef}
          />
        </div>

        <Button
          // disabled={loading}
          type="submit"
          sx="h-11 over-rounded-full font-bold"
          variant="purple-filled"
        >
          {loading ? <SvgLoadingDots /> : "Login"}
        </Button>
        <div className="text-xs mt-3">
          Don’t have an account? <NavLink to="/signup">Sign up</NavLink>
        </div>
      </form>
    </div>
  );
};

export default Login;
