import React from "react";
import { Navigate } from "react-router-dom";
import { useAccountStore } from "store";

const RouteHiddenForAuthenticated = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { loggedIn, registerProgress } = useAccountStore();
  return (loggedIn && registerProgress) === 0 ? <Navigate to="/" /> : children;
};

export default RouteHiddenForAuthenticated;
